import {Environment} from './environment.model';

// not for local serve/run!! (-> environment.prod-local.ts)

export const environment: Environment = {
  production: true,
  stage: '_STAGE_',
  optionalModules: [],
  version: '_VERSION_',
  endpoints: {
    backendBaseUrl: '/api', // reverse proxy requests through frontend pod to skip OPTIONS (CORS) requests
    backendWebsocketUrl: '/ws' // reverse proxy ws as some firewalls block cross-origin ws
  },
  cms: {
    url: '_CMS_URL_',
    token: '_CMS_TOKEN_'
  },
  sessionRecording: '_SESSION_RECORDING_',
  disableZusatzleistungen: '_FF_DISABLE_ZUSATZLEISTUNGEN_' as 'true' | 'false',
  disableFahrplan: '_FF_DISABLE_FAHRPLAN_' as 'true' | 'false',
  idleWarningTimeMinutes: '_IDLE_WARNING_TIME_MINUTES_'
};
